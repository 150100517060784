<i18n>
  {
    "es": {
      "CHECKBOX": "Checkbox",
    },
    "fr": {
      "CHECKBOX": "Case à cocher"
    },
    "de": {
      "CHECKBOX": "Kontrollkästchen"
    },
    "it": {
      "CHECKBOX": "Casella di controllo"
    },
    "pt": {
      "CHECKBOX": "Caixa de seleção"
    }
  }
</i18n>

<template>
  <label class="flex items-center space-x-4">
    <button
      :aria-label="text + t('CHECKBOX')"
      type="button"
      @click="emit('select')"
      class="rounded-full min-w-[32px] w-8 h-8 border-1 fill-transparent hover:shadow-[inset_0_0_0_15px_#000000] border-black relative cursor-pointer grid place-items-center hover:border-black before:w-0.5 before:h-0.5 transition-shadow duration-500"
      :class="
        selected
          ? 'after:bg-sanremo-black after:w-full z-0 after:h-full after:absolute after:rounded-full after:top-0 after:left-0 before:bg-white  before:scale-[6.4] before:transition-all before: before:absolute before:rounded-full before:z-10'
          : ''
      "
    ></button>
    <span class="text-sm whitespace-nowrap tracking-wider cursor-pointer">{{
      text
    }}</span>
  </label>
</template>

<script setup>
const props = defineProps({
  selected: Boolean,
  value: String,
  text: String,
})

const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['select'])
</script>
